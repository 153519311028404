import React from "react"
import { Helmet } from "react-helmet"

export default () => (
  <>
    <Helmet>
      <meta name="google-site-verification" content="qiazwsqTcb2fjp1LASIiUyvzRPn6_iosY4uMdjE1xLI" />
    </Helmet>
    <div>Hello world!</div>
  </>
)
